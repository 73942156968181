import React, { useContext, useEffect, useState } from 'react';

import { MainContext } from '../contexts/MainContext';
import gsap from 'gsap';
import tablet from '../img/biography-tablet.jpg';
import desktop from '../img/biography-desktop.jpg';
import Arrow from '../svg/arrow';

let tl = gsap.timeline();

const Biography = () => {
  const [english, setEnglish] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  const { getBiography, biography, closeMenu } = useContext(MainContext);
  const {
    biography_highlight,
    biography_highlight_polish,
    biography_text,
    biography_text_polish
  } = biography

  useEffect(() => {
    getBiography();
    closeMenu();
    tl.to('.biography-bg', {
      duration: 1,
      ease: 'ease-in-out',
      css: {
        opacity: 1,
        width: '105%',
        height: '105%'
      }}
    ).to('.biography-container', {
      duration: 0.8,
      css: {
        opacity: 1,
        y: 0
      }}
    )
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollDown)
    return (() => window.removeEventListener('scroll', scrollDown))
  }, [])

  const debounce =  (func, wait, immediate) => {
    let timeout;
    return function() {
      let context = this, args = arguments;
      let later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  const scrollDown = debounce(() => {
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    let scroll = {y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop};
    if(scroll.y >= 20){ 
       setScrolled(true);
    } else if (scroll.y < 20) {
      setScrolled(false);
    }
  }, 1)

  return (
    <div className="biography">
      <picture className="biography-bg">
        <source srcSet={tablet} media="(max-width: 800px)" />
        <source srcSet={desktop} media="(min-width: 801px)" />
        <img src={tablet} alt="" />
      </picture>
      <div className="page-content">
        <div className="biography-container">
          {english && <h1 className="title">Biography</h1>}
          {!english && <h1 className="title">Biografia</h1>}
          <div className="biography-languages">
            <button onClick={() => setEnglish(true)}>English</button>
            <button onClick={() => setEnglish(false)}>Polski</button>
          </div>
          {english && <div className="biography-header" dangerouslySetInnerHTML={{ __html: biography_highlight }}></div>}
          {!english && <div className="biography-header" dangerouslySetInnerHTML={{ __html: biography_highlight_polish }}></div>}
          {english && <div className="entry biography-content" dangerouslySetInnerHTML={{ __html: biography_text }}></div>}
          {!english && <div className="entry biography-content" dangerouslySetInnerHTML={{ __html: biography_text_polish }}></div>}
        </div>
      </div>
      {!scrolled && <div className="scroll-down"><Arrow /></div>}
      
    </div>
  )
}

export default Biography;
