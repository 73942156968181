import React, { createContext, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

export const MainContext = createContext();

const MainContextProvider = (props) => {
  const [announcements, setAnnouncements] = useState([]);
  const [social, setSocial] = useState({});
  const [contact, setContact] = useState({});
  const [biography, setBiography] = useState({});
  const [videos, setVideos] = useState([]);
  const [pressTotal, setPressTotal] = useState([]);
  const [press, setPress] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [pastEventsTotal, setPastEventsTotal] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  let url;

  useEffect(() => {}, [
    isMenuOpen,
    setAnnouncements,
    setSocial,
    setContact,
    setBiography,
    setVideos,
    setPressTotal,
    setPress,
    setPhotos,
    setPastEvents,
    setFutureEvents,
    setPastEventsTotal,
    setIsMenuOpen,
  ]);

  url = "https://martagardolinska.com";

  const getAnnouncements = useCallback(async () => {
    const result = await axios.get(
      `${url}/wp-json/acf/v3/options/announcements`
    );
    let cleanedString = result.data.substring(0, result.data.indexOf("<pre>"));
    let dataObj = JSON.parse(cleanedString);
    const ans = dataObj.acf.announcements;
    setAnnouncements([...ans]);
  }, [setAnnouncements, url]);

  const getSocial = useCallback(async () => {
    const result = await axios.get(`${url}/wp-json/acf/v3/options/social`);
    let cleanedString = result.data.substring(0, result.data.indexOf("<pre>"));
    let dataObj = JSON.parse(cleanedString);
    setSocial({ ...dataObj.acf });
  }, [setSocial, url]);

  const getContact = useCallback(async () => {
    const result = await axios.get(`${url}/wp-json/acf/v3/options/contact`);
    let cleanedString = result.data.substring(0, result.data.indexOf("<pre>"));
    let dataObj = JSON.parse(cleanedString);

    setContact({ ...dataObj.acf });
  }, [setContact, url]);

  const getBiography = useCallback(async () => {
    const result = await axios.get(`${url}/wp-json/acf/v3/options/biography`);

    let cleanedString = result.data.substring(0, result.data.indexOf("<pre>"));
    let dataObj = JSON.parse(cleanedString);

    setBiography({ ...dataObj.acf });
  }, [setBiography, url]);

  const getVideos = useCallback(async () => {
    const result = await axios(`${url}/wp-json/acf/v3/options/videos`);
    let cleanedString = result.data.substring(0, result.data.indexOf("<pre>"));
    let dataObj = JSON.parse(cleanedString);
    let vids = dataObj.acf.video_list;
    setVideos([...vids]);
  }, [setVideos, url]);

  const getPress = useCallback(
    async (size) => {
      const result = await axios(`${url}/wp-json/wp/v2/reviews`, {
        params: {
          per_page: 100,
        },
      });

      let cleanedString = result.data.substring(
        0,
        result.data.indexOf("<pre>")
      );
      let dataObj = JSON.parse(cleanedString);

      const rev = dataObj;
      setPressTotal(rev);
      var items = rev.slice(0, size);
      setPress([...items]);
    },
    [setPress, url]
  );

  const getEvents = useCallback(
    async (size) => {
      const result = await axios(`${url}/wp-json/wp/v2/events`, {
        params: {
          per_page: 100,
        },
      });

      let cleanedString = result.data.substring(
        0,
        result.data.indexOf("<pre>")
      );
      let dataObj = JSON.parse(cleanedString);

      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      let now = parseInt(yyyy + "" + mm + "" + dd);

      let future = dataObj.filter((f) => parseInt(f.acf.date_event) >= now);
      let past = dataObj.filter((p) => parseInt(p.acf.date_event) < now);

      function Asc(a, b) {
        const dateA = a.acf.date_event;
        const dateB = b.acf.date_event;

        let comparison = 0;
        if (dateA > dateB) {
          comparison = 1;
        } else if (dateA < dateB) {
          comparison = -1;
        }
        return comparison;
      }

      function Desc(a, b) {
        const dateA = a.acf.date_event;
        const dateB = b.acf.date_event;

        let comparison = 0;
        if (dateA < dateB) {
          comparison = 1;
        } else if (dateA > dateB) {
          comparison = -1;
        }
        return comparison;
      }

      future.sort(Asc);
      past.sort(Desc);

      setFutureEvents(future);
      setPastEventsTotal(past);
      const pastSliced = past.slice(0, size);
      setPastEvents([...pastSliced]);
    },
    [setPastEventsTotal, setPastEvents, setFutureEvents, url]
  );

  const getPhotos = async () => {
    const result = await axios(`${url}/wp-json/acf/v3/options/photos`);
    let cleanedString = result.data.substring(0, result.data.indexOf("<pre>"));
    let dataObj = JSON.parse(cleanedString);

    const pho = dataObj.acf.photo_gallery;

    const photo_urls = pho.map((photo) => {
      return {
        src: photo.url,
        width: photo.sizes["2048x2048-width"],
        height: photo.sizes["2048x2048-height"],
      };
    });
    setPhotos([...photo_urls]);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <MainContext.Provider
      value={{
        announcements,
        getAnnouncements,
        contact,
        getContact,
        biography,
        getBiography,
        social,
        getSocial,
        videos,
        getPress,
        press,
        pressTotal,
        getPhotos,
        photos,
        getVideos,
        pastEvents,
        futureEvents,
        pastEventsTotal,
        getEvents,
        isMenuOpen,
        toggleMenu,
        closeMenu,
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default withRouter(MainContextProvider);
