import React, { useEffect } from 'react';
import gsap from 'gsap';

let tl = gsap.timeline();

const Quote = ({highlight, text, author, sourceText, sourceLink}) => {
  useEffect(() => {
    tl.to('.quote', {
      duration: 1,
      css: {
        opacity: 1,
        y: 0
      }}
    )
  }, [])

  return (
    <div className="quote">
      {highlight && <div className="quote-highlight" dangerouslySetInnerHTML={{ __html: highlight }}></div>}
      {text && <div className="quote-text" dangerouslySetInnerHTML={{ __html: text }}></div>}

      <div className="quote-footer">
        {author && <span className="quote-author">{author}</span>}
        {sourceText && <a className="quote-source" rel="noopener noreferrer"target="_blank" href={sourceLink}>{sourceText}</a>}
      </div>
    </div>
  )
}

export default Quote;
