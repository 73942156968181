import React, { useContext, useEffect, useState } from 'react';
import gsap from 'gsap';
import { MainContext } from '../contexts/MainContext';
import Event from './Event';

let tl = gsap.timeline();

const Calendar = () => {
  const [size, setSize] = useState(0);
  const [more, setMore] = useState(false);
  const {  getEvents, pastEvents, pastEventsTotal, futureEvents, closeMenu } = useContext(MainContext);

  useEffect(() => {
    getEvents(0)
    setSize(0);
    closeMenu();
    tl.to('.calendar', {
      duration: 1,
      css: {
        opacity: 1,
        y: 0
      }}
    )
  }, [])

  const getMore = () => {
    getEvents(size + 4);
    setSize(size + 4);
    setMore(true);
  }

  return (
    <div className="page calendar">
      <div className="page-header container">
        <h1 className="title">Calendar</h1>
      </div>
      <div className="event-list container">
        <div className="events-future">
        
        {
          futureEvents.map((event, index) => {
            const {
              date_event,
              location_name_event,
              orchestra_event,
              soloists_event,
              text_event,
              time_event,
              title_event
            } = event.acf

            return (
              <Event
                key={index}
                title={title_event}
                orchestra={orchestra_event}
                soloists={soloists_event}
                date={date_event}
                text={text_event}
                location={location_name_event}
                time={time_event}
              />
            )
          })
        }
        </div>

        <div className="events-past">
          <span className="events-divider"></span>
          {more && <h3 className="past-title">Past events</h3>}

          {
            pastEvents.map((event, index) => {
              
              const {
                date_event,
                location_name_event,
                orchestra_event,
                soloists_event,
                text_event,
                time_event,
                title_event
              } = event.acf

              return (
                <Event
                  key={index}
                  title={title_event}
                  orchestra={orchestra_event}
                  soloists={soloists_event}
                  date={date_event}
                  text={text_event}
                  location={location_name_event}
                  time={time_event}
                />
              )
            })
          }
        </div>

      </div>
      { pastEventsTotal.length > pastEvents.length &&  (
        <div className="more container">
          <button onClick={getMore}>Past Events</button>
        </div> 
      )}
    </div>
  )
}

export default Calendar;
